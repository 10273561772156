@import '../../assets/styles/variables';

button.continue-btn {
  &.MuiButton-root {
    margin: 16px;
    width: calc(100% - 32px);
    font-size: 16px;
    border: $connect-primary;

    &:not(.disabled) {
      background-color: $connect-primary;
      color: $white;
    }

    &.connect {
      border: $connect-primary;
      &:not(.disabled) {
        background-color: $connect-primary;
      }
    }

    &.is {
      border: $is-primary;
      &:not(.disabled) {
        background-color: $is-primary !important;
      }
    }

    &.sura {
      border: $sura-primary;
      &:not(.disabled) {
        background-color: $sura-primary;
      }
    }

    &.bcr {
      border: $bcr-primary;
      &:not(.disabled) {
        background-color: $bcr-primary !important;
      }
    }

    &.ins {
      border: $ins-primary;
      &:not(.disabled) {
        background-color: $ins-primary !important;
      }
    }
  }
}

button.back-btn {
  &.MuiButton-root {
    margin: 16px;
    width: calc(100% - 32px);
    font-size: 16px;
    border: $connect-primary;
    background-color: ghostwhite;
    color: black;

    &.connect {
      border: $connect-primary;
      &:not(.disabled) {
        background-color: $connect-primary;
      }
    }

    &.is {
      border: $is-primary;
      &:not(.disabled) {
        background-color: $is-primary !important;
      }
    }

    &.sura {
      border: $sura-primary;
      &:not(.disabled) {
        background-color: $sura-primary !important;
      }
    }

    &.bcr {
      border: $bcr-primary;
      &:not(.disabled) {
        background-color: $bcr-primary !important;
      }
    }

    &.ins {
      border: $ins-primary;
      &:not(.disabled) {
        background-color: $ins-primary !important;
      }
    }
  }
}

button.MuiButton-root.delete-btn {
  margin: 16px;
  border: $black;
  width: calc(100% - 32px);
  font-size: 16px;

  &:not(.disabled) {
    background-color: $black;
    color: $white;
  }

  svg {
    margin-right: 0.8rem;
    margin-top: -0.1rem;
  }
}
