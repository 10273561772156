@use 'sass:math';
@import '../assets/styles/variables';

$lead-line-height: 22px;
$thumbnail-size: 170px;
$icon-size: 50px;
$gap: 10px;

.photo-section-screen {
  height: 100%;
  display: flex;
  flex-direction: column;

  .container {
    height: 100%;
    max-height: calc(100% - 42px - 32px);
  }

  h3.lead {
    display: flex;
    align-items: center;

    // Setting these so as to be predictable
    margin-top: $md-gutter;
    line-height: $lead-line-height;
    margin-bottom: $lg-gutter;
    margin-left: $md-gutter;
    margin-right: $md-gutter;

    svg {
      height: 20px;
      margin-right: $md-gutter;
    }
    span {
      margin-right: $md-gutter;
    }
  }

  .photos-scrollable-area {
    width: 100%;

    // The 42px and 32px are for the continue button
    max-height: calc(
      100% - #{$md-gutter} - #{$lead-line-height} - #{$lg-gutter}
    );
    overflow: scroll;

    display: flex;
    align-items: center;
    flex-direction: column;

    input[type='file'] {
      display: none;
    }
  }

  .photos-container {
    flex: 1;
    width: 21rem;
    padding: $gap;

    display: flex;
    gap: $gap;
    flex-wrap: wrap;

    .image-upload-thumbnail,
    .image-preview-thumbnail {
      height: $thumbnail-size + 60px;
      width: calc(50% - #{math.div($gap, 2)});
      max-width: $thumbnail-size;
      position: relative;

      .custom-file-upload {
        border: 1px solid $lightgray;
        border-radius: 4px;
        background: white;
        cursor: pointer;

        height: $thumbnail-size;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        gap: 10px;
        padding: 10px;
        box-sizing: border-box;
        font-size: 16px;
        .camera-plus-icon {
          width: $icon-size;
          height: $icon-size;
        }
      }
      .ayuda {
        display: flex;
        justify-content: center;
        align-items: center;

        margin: $xm-gutter auto 0;
        width: 90%;
        padding: 8px;

        color: white;
        background: #222;

        border-radius: 50px;
        svg {
          width: 16px;
          margin-right: 6px;
          fill: white;
        }
        span {
          line-height: 20px;
        }
      }
    }

    .image-preview-thumbnail {
      position: relative;
      img {
        height: $thumbnail-size;
        width: 100%;

        border: 1px solid $lightgray;
        border-radius: 4px;
        background: white;
        cursor: pointer;
        box-sizing: border-box;
      }

      .image-preview-not-valid {
        border-color: orange;
        border-width: 3px;
      }

      .preview-label {
        position: relative;
        top: -43px;
        background: #000000c9;
        color: white;
        height: 40px !important;
        padding: 0;
        display: flex;
        justify-content: center;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border: 1px solid $lightgray;
        border-top: 0px;
        text-align: center;
        align-items: center;
      }

      .preview-label-not-valid {
        border-color: orange !important;
        border-width: 3px !important;
      }
    }
  }

  .button-panel {
    box-shadow: 0 12px 16px hsl(0deg, 0%, math.div(0%, 0.25));
    z-index: 1;
    padding-bottom: 0.1rem;
  }

  .full-screen-preview {
    position: fixed;
    height: 100%;
    width: 100%;
    background: #000000dd;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    z-index: 2;

    img {
      width: 100%;
      max-height: 80%;
    }
    .action-buttons {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      gap: 20px;
    }
  }

  .vh-side-alert {
    position: fixed;
    z-index: 2;
    margin-top: 3.2rem;
  }
  .vh-side-alert-upload {
    margin-top: 8.5rem;
  }
  .vh-side-alert-upload-top {
    margin-top: 3.2rem;
  }
  .vh-side-alert-top {
    margin: -1rem 1rem 0 0;
    position: fixed;
    top: 25px;
  }
}

.photo-help-modal {
  padding: 7px;
  img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }
  p {
    text-align: justify;
    font-size: 18px;
  }
  .detail {
    font-size: 16px;
    margin-bottom: 0px;
  }

  .action-buttons {
    display: flex;
  }

  .button-left {
    margin-right: 3px;
    width: 100%;
    text-align: center;

    button {
      margin-top: 0;
    }
  }

  .button-right {
    width: 100%;
    margin-left: 3px;
  }
}

.photo-help-modal .MuiButton-textPrimary {
  margin-top: 0.9rem;
  font-size: 13px;
}

.photo-help-modal .MuiButton-contained {
  font-size: 13px !important;
}

.photo-validation-modal {
  padding: 7px;
  img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }
  p {
    text-align: justify;
    font-size: 16px;
  }
  .detail {
    font-size: 16px;
    margin-bottom: 0px;
  }
}

.photo-validation-modal .MuiButton-contained {
  font-size: 13px !important;
}

#warning-msg-container {
  position: fixed;
}

.sura {
  #warning-msg-container {
    margin: 0 -15px;
  }
}

.single-item-thubmanil-width {
  width: 100% !important;
}
