@import '../assets/styles/variables';

.congrats {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background-image: url('../assets/imgs/congrats.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;

  h2 {
    margin: $splash-margins;
  }

  p {
    margin: 0 $splash-margins;
  }
}
