@import '../assets/styles/variables';

.splash {
  display: flex;
  height: 31.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  picture {
    width: 70%;
    img {
      width: 100%;
    }
  }
  h3 {
    position: absolute;
    bottom: 0;
    font-weight: 500;
    font-size: 14px;

    span {
      font-weight: 700;
      font-size: 16px;
      color: $black;
    }
  }
  .footer-message {
    position: absolute;
    font-weight: 500;
    font-size: 14px;
    bottom: 2.6rem;
    padding: 0rem 1.3rem;
  }
}
