@import '../../../assets/styles/variables';

.steps {
  display: flex;
  background: $white;
  overflow-x: auto;
  padding: 0 10px;

  .theme-color {
    &.steps__tab {
      background-color: $ghost;
      margin: 7px 4px;
      padding: 6px 4px 9px;
      text-align: center;
      border-radius: 22px;
      min-width: 149px;
      font-weight: 500;
      cursor: pointer;

      &--selected {
        background-color: $slate-selected;
        color: $white;
      }
      &--error {
        background-color: $slate-gray;
        color: #f44336 !important;
      }
    }
  }
}

@media (min-width: $md) {
  .steps {
    background: $ghost;
    border-radius: 38px;
    width: fit-content;
    padding: 0;
    margin: 0 16px;

    &__tab {
      margin: 0;

      &--selected {
        color: $slate-gray;
        background-color: $white;
      }
    }
  }
}
