@import '../../assets/styles/variables';
$icon-padding: 10px;

.signature {
  &__landscape {
    position: fixed;
    top: 0;
    left: 0;
    background: $white;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 100;
  }

  &__rotateIcon {
    width: 100px;
    height: 100px;
  }

  &__confirmButton {
    margin-top: 30px;
    width: 400px;
    padding: 10px;

    &:enabled {
      color: $white;
      background-color: $connect-primary;
      border-radius: 4px;
      border: none;
      cursor: pointer;
    }

    &.connect:enabled {
      background-color: $connect-primary;
    }

    &.is:enabled {
      background-color: $is-primary;
    }

    &.sura:enabled {
      background-color: $sura-primary;
    }
  }

  &__canvasContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &__helpText {
    position: absolute;
    height: 18px;
    width: 200px;
    top: calc(50% - 9px);
    left: calc(50% - 100px);
    text-align: center;
    color: #979797;
  }

  &__canvas {
    border-bottom: 2px solid #e7ebec;
    background-color: lightgray;
  }

  &__clearIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: $icon-padding;
    right: $icon-padding;
  }

  &__preview {
    border-bottom: 2px solid #e7ebec;
    height: 100px;

    // so that .edit-pen and icons be absolute with respect to this.
    position: relative;
  }

  &__preview-error {
    border-bottom: 2px solid #f44336;
    height: 100px;

    // so that .edit-pen and icons be absolute with respect to this.
    position: relative;
  }

  &__placeholder {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $alto;
    font-size: 18px;
  }

  &__previewImage {
    width: 100%;
    height: 100%;
  }

  &__editIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: $icon-padding;
    left: $icon-padding;
    transform: scaleX(-1);
  }
}

@media screen and (orientation: portrait) {
  .signature {
    &__canvasContainer {
      display: none;
    }

    &__confirmButton {
      display: none;
    }
  }
}
@media screen and (orientation: landscape) {
  .signature {
    &__message,
    &__rotateIcon {
      display: none;
    }
  }
}
