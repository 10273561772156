#gallery-container {
  .button {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    font-size: 15px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }

  .button-danger {
    background-color: black;
    color: white;
  }

  .button-left {
    margin-right: 3px;
  }

  .button-right {
    margin-left: 3px;
  }

  .loading {
    position: fixed;
  }

  .photo-preview-modal {
    text-align: center;
  }

  .preview-photo {
    max-width: 100%;
  }

  .gps-position {
    font-size: 0.5rem;
    position: fixed;
    right: 1rem;
    top: 4.4rem;
  }

  .gps-icon {
    height: 0.7rem;
    width: 0.7rem;
  }
}

.sura {
  #gallery-container {
    .loading {
      margin: 0 -15px;
    }
  }
}
