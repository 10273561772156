@import '../assets/styles/variables';

.modal-backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .modal {
    background: white;
    margin: $md-gutter;
    padding: $md-gutter;
    border-radius: 4px;

    h2 {
      text-align: center;
      margin-top: 0;
    }
    div {
      margin-bottom: $md-gutter;
    }
    button {
      margin: 0;
      width: 100%;
    }
  }

  .action-buttons {
    display: flex;
  }
}
