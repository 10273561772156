@import '../assets/styles/variables';

.onboarding {
  display: flex;
  flex-direction: column;
  height: 100%;

  .react-multi-carousel-list,
  .react-multi-carousel-track {
    height: 100%;
  }
}
