@import '../assets/styles/variables';

.form-screen {
  min-height: 100%;

  display: flex;
  flex-direction: column;

  .container {
    margin: 0 $md-gutter;
    flex: 1;
  }

  h3.lead {
    margin-bottom: 2 * $md-gutter;
    font-size: 18px;
  }

  .photo-section-links {
    flex: 1;
  }
  .photo-section-link {
    text-decoration: none;
    color: $text;

    // Center vertically
    .label {
      display: flex;
      align-items: center;
      svg {
        margin-right: $md-gutter;
      }
    }

    height: 50px;
    border: 1px solid $lightgray;
    margin-bottom: $md-gutter;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $sm-gutter;
  }
}
