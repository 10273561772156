.warning {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  text-align: center;

  &__container {
    padding: 0 1rem;
    width: 90%;
  }

  &--alert {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
      contrast(103%) !important;
  }
}
