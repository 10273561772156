@import '../../assets/styles/variables';

.indicators {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;

  .indicator {
    border: 1px solid $gray;
    width: 10px;
    height: 10px;
    border-radius: 40px;
    margin: 0 2px;

    &.active {
      width: 16px;
      background-color: $secondary;
    }
  }
}
