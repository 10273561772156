@import '../assets/styles/variables';

.inactive {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  picture {
    width: 57%;
    position: absolute;
    top: 39px;

    img {
      width: 100%;
    }
  }
  h2 {
    //box-sizing: border-box;
    text-align: center;
    padding: 0 13px;
  }
  h3 {
    position: absolute;
    bottom: 0;
    font-weight: 500;
    font-size: 14px;

    span {
      font-weight: 700;
      font-size: 16px;
      color: $black;
    }
  }
}
