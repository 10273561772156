@import '../assets/styles/variables';

.finished {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 3rem;
  }

  .no-image {
    margin: 4rem 0;
  }

  &__footer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 3rem;

    .btnContact {
      display: contents;
      button.continue-btn.MuiButton-root {
        background-color: black !important;
      }
    }
  }

  h2 {
    margin: $splash-margins;
    text-align: center;
    font-size: 1.4rem;
  }

  .loading {
    h2 {
      font-size: 1.5rem;
    }
    position: fixed !important;
  }

  p {
    margin: 0 $splash-margins;
    text-align: center;
    &.finished__info {
      font-size: 1rem;
      margin: 0px 2rem 0rem 2rem;
    }
  }

  button {
    position: absolute;
    bottom: 0;
  }

  #pre-id {
    font-size: 1.7rem;
    font-weight: bold;
    text-align: center;
    margin-top: 0.4rem;
  }

  .icon-finish {
    text-align: center;
    margin: 3% 0 3%;
  }

  .icon-info {
    height: 8%;
    width: 15%;
    text-align: center;
    margin: -3% 0 3%;
    path,
    rect {
      fill: black !important;
    }
  }
}
