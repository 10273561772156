$text: #555;
$primary: #0b9548;
$secondary: #584685;
$black: #000000;
$white: #ffffff;
$gray: #777;
$lightgray: #ddd;
$danger-red: #ff7777;
$ghost: #e2e3e0;
$slate-gray: #0b9546;
$slate-selected: #1a1a1a;
$alto: #d8d8d8;
$blue-1: #2e5aac;
$blue-2: #89a7e0;
$white-1: #eef2fa;
$gray-1: #b9c1cc;
$aqua-haze: #f1f4f7;
$warning-border: #ff8f39;
$warning-bg: #fff4ec;
$warning-color: #b95000;
$success-border: #5aca75;
$success-bg: #edf9f0;
$success-color: #287d3c;

// Brand colors
$connect-primary: #f15b2b;
$connect-secondary: #53565a;
$is-primary: #518e40;
$is-secondary: #a29bff;
$bcr-primary: #164496;
$sura-primary: #2D6DF6;
$sura-secondary: #00aec7;
$ins-primary: #1e6f97;
$ins-secondary: #1e6f97;


$xm-gutter: 6px;
$sm-gutter: 10px;
$md-gutter: 15px;
$lg-gutter: 35px;
$splash-margins: 30px;

//Sizes
$xs: 0px;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;

$font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
