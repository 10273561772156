@import './../assets/styles/variables';

.preInspection {
  .img-logo {
    width: 40%;
  }
  &__sradio {
    margin-top: 1px;
    margin-bottom: 8px;
    label {
      margin-left: 3px;
      font-size: 13px;
    }
  }
  &__chipStatus {
    margin-top: 1px;
    margin-bottom: 8px;
    label {
      margin-left: 3px;
      font-size: 13px;
    }
  }
  &__toggle-button.MuiButton-outlinedPrimary {
    background-color: $white;
    border-color: $primary;
    color: $primary;
    margin: 0 4px;
    min-width: 100px;
  }
  &__toggle-button-error.MuiButton-outlinedPrimary {
    background-color: $white;
    border-color: #ff0000;
    color: #ff0000;
    margin: 0 4px;
    min-width: 100px;
  }
  &__toggle-button.MuiButton-outlinedPrimary:hover {
    background-color: rgba($color: $primary, $alpha: 0.7);
    color: $white;
  }
  &__toggle-button--selected.MuiButton-outlinedPrimary,
  &__toggle-button--selected.MuiButton-outlinedPrimary:hover {
    background-color: $primary;
    color: $white;
    border-color: $primary;
  }
}
.MuiFormControl-root {
  margin: 0.5rem 0 !important;
  width: 100%;
  input,
  textarea {
    text-transform: uppercase;
  }
}
