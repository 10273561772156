@import '../assets/styles/variables';

.video-section-screen {
  .photos-scrollable-area {
    padding: $md-gutter;
    padding-top: 0;
  }

  .video-instructions {
    margin-top: 0;
  }
  .custom-file-upload {
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
    color: $text;
    background-color: transparent;
    width: 100%;
    height: 50px;
    border: 1px solid $lightgray;
    margin-bottom: $md-gutter;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $sm-gutter;

    svg {
      height: 100%;
      margin-right: $md-gutter;
    }
  }

  .video-processed {
    margin: 1rem 0;
  }

  .info-alert.MuiAlert-root {
    color: $blue-1;
    border: 1px solid $blue-2;
    margin-bottom: 1rem;

    .MuiAlert-icon {
      color: $blue-1;
    }

    &.MuiAlert-standardWarning {
      color: $warning-color;
      border: 1px solid $warning-border;
      background-color: $warning-bg;

      .MuiAlert-icon {
        color: $warning-color;
      }
    }

    &.MuiAlert-standardSuccess {
      color: $success-color;
      border: 1px solid $success-border;
      background-color: $success-bg;

      .MuiAlert-icon {
        color: $success-color;
      }
    }
  }
}

#size-alert .MuiAlert-message {
  margin-bottom: 0px;
}
