@import '../assets/styles/variables';

.shared-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 320px;
  width: 50%;
  max-width: 650px;
  margin: 0 auto;
  &__content.MuiCardContent-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 2rem;
    color: $gray-1;

    .cp-divider {
      width: 100%;
      margin: 2rem 0;
    }

    .cp-link {
      padding: 0 1rem;
      border-radius: 10px;
      background-color: $aqua-haze;
      display: flex;
      justify-content: space-between;
      align-items: center;
      user-select: none;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__button {
        color: black;
      }
    }

    &:last-child {
      padding-bottom: 5rem;
    }

    input {
      text-transform: lowercase;
      text-align: center;
      font-size: 1.1rem;
    }
    .contentTab {
      margin: 0 16px;
    }
    h2 {
      text-align: center;
      font-size: 24px;
    }
    h3 {
      font-size: 18px;
      text-align: center;
      font-weight: 500;
    }
  }
  p {
    text-align: center;
  }
  img {
    width: 550px;
  }
  .rdButtom {
    padding-left: 25%;
  }
}
.shared-screen img {
  margin-top: -52px;
  width: 100%;
}
.secondTab {
  width: 21rem;
}
.secondTab button.continue-btn.MuiButton-root {
  margin: 16px 0;
  width: 100%;
}

@media screen and (max-width: 600px) and (min-width: 100px) {
  canvas {
    width: 175px;
  }
  .shared-screen {
    width: 65%;
    height: 35rem;
    h2 {
      text-align: center;
      font-size: 24px;
    }
    h3 {
      font-size: 18px;
      text-align: center;
      font-weight: 500;
    }
    .rdButtom {
      padding-left: 40px;
    }
  }
  .shared-screen img {
    margin-top: -52px;
    width: 100%;
  }
  .shared-screen__content.MuiCardContent-root .contentTab {
    width: 100%;
    margin: 0;
  }
  .shared-screen__content.MuiCardContent-root {
    padding: 2rem;
  }
  .shared-screen__content.MuiCardContent-root .cp-divider {
    margin: 1.7rem 0;
  }
  .firstTab button.continue-btn.MuiButton-root {
    margin: 16px 0;
    width: 100%;
  }
  .secondTab {
    width: 17rem;
  }
  .secondTab button.continue-btn.MuiButton-root {
    margin: 16px 0;
    width: 100%;
  }
  .shared-screen__content.MuiCardContent-root .cp-link {
    width: 100%;
  }
}
