@import '../assets/styles/variables';

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: 1.5rem;

  h2 {
    text-align: center;
    font-size: 1.3rem;
    color: $primary;
    text-transform: uppercase;
  }
  p {
    font-size: 0.9rem;
  }
  &__summary {
    margin: 0.5rem 0rem 2rem;

    .vehicle {
      font-size: 1rem;
      margin: 0.5rem 3rem;
      padding: 0.3rem 0;
      text-transform: uppercase;
      border: 1px solid $lightgray;
    }

    p {
      text-align: center;
    }
  }
  .footer-info {
    text-align: center;
    padding-bottom: 1rem;
    p {
      font-weight: bold;
      font-size: 1rem;
    }
  }

  .icon-welcome {
    margin: 0 auto;
  }
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}
