@import '../../assets/styles/variables';

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      text-align: center;
      margin: $splash-margins;
    }

    p {
      text-align: center;
      margin: 0 $splash-margins;
    }

    ul {
      margin-top: 1.2rem;
    }

    li {
      margin-bottom: 0.8rem;
    }
    .photo-intro-description {
      padding: 0 40px 0;
      text-align: justify;
      ul {
        margin-top: 0;
        padding-left: 25px;
      }
    }
    .body-info-text {
      width: 350px;
      height: 313px;
      overflow: auto;
      text-align: center;
      font-size: 15px;
      margin-bottom: 0.8rem;
      margin-top: 0.8rem;
    }
  }
  .image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
  .circle {
    width: 200px;
    height: 200px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    background: #f2f8f5;
  }
}

.is {
  .page {
    .text-container {
      .body-info-text {
        height: 100%;
      }
    }
  }
}
