@import '../../../assets/styles/variables';

h3.lead2 {
  text-align: center;
  margin-bottom: 2 * $md-gutter;
  font-size: 18px;
}

.info-alert.MuiAlert-root.MuiAlert-standardInfo {
  color: $blue-1;
  border: 1px solid $blue-2;
  margin: 1rem 0;

  .MuiAlert-icon {
    color: $blue-1;
  }
}
